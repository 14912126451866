import React from 'react'
import './main.css';

function Header() {
  return (
          <div className="ServiceTicket-header">
        
      <h4>Your Property Manager:</h4>
      <h6><em>Joe Bhaktiar</em></h6>
      
    </div>
  )
}

export default Header;