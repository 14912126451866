import React from 'react';
import { Button } from "react-bootstrap";

const globalVar = JSON.parse(localStorage.getItem('globalVar'));

const S3_BUCKET = process.env.REACT_APP_S3_BUCKET;
const DIR_NAME = 'missinginformation/' + new Date(); // Notice that if you don't provide a dirName, the file will be automatically uploaded to the root of your bucket

class MissingInformation extends React.Component {
    constructor() {
        super();
        this.state = {
            tenantSsn: '',
            tenantId: '',
            tenantName: '',
        };
    }

    pingServer() {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        };
        const apiUrl = globalVar.homeApiUrl + '/pingserver/missinginformation';
        fetch(apiUrl, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                // console.log("user exists?: ", data);
                if (data !== true) {
                    window.location.href = '/error'; // redirect to error page
                }
            }).catch(function () {
                window.location.href = '/error';
            });
    }

    componentDidMount() {
        this.pingServer();
    }

    myChangeHandler = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({ [nam]: val });
        // console.log('nam : ',nam);
        // console.log('val : ',val);
    }

    mySubmitHandler = (event) => {
        const apiUrl = globalVar.homeApiUrl + '/missinginformation';
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(this.state)
        };
        fetch(apiUrl, requestOptions)
            .then(data => {
                if (data.ok) {
                    alert('Your information has been successfully submitted, someone will contact you shortly.');
                    window.location.href = '/login';
                }
                else {
                    alert('Your information was not submitted, please try again or contact your property manager.');
                }
            }
            )
            .catch(err => alert(err));
    }

    validateForm = () => {
        if (this.state.tenantSsn && this.state.tenantName) {
            return true;
        }
        else {
            return false;
        }
    }

    render() {
        return (
            <div>
                <br /><br />
                <p>Name: <i style={{ color: 'red' }}>(Required)</i></p>
                <input class="form-control w-50" type='text' name='tenantName' required onChange={this.myChangeHandler} />
                <br />
                <p>SSN (numbers only, no '-' or spaces): <i style={{ color: 'red' }}>(Required)</i></p>
                <input class="form-control w-50" type='number' placeholder="123456789" pattern="[0-9]{9}" name='tenantSsn' required onChange={this.myChangeHandler} />
                <br /><br />
                <Button onClick={this.mySubmitHandler} disabled={!this.validateForm()}>
                    Submit
                </Button>
                <br /><br /><br /><br />
            </div>
        );
    }
}

export default MissingInformation;