import React from 'react';
import { Button } from "react-bootstrap";
import globalVar from './globalVar';

// const globalVar = JSON.parse(localStorage.getItem('globalVar'));
let newArr = '';
const formData = new FormData();
class MissingInformation extends React.Component {
    constructor() {
        super();
        this.state = {
            tenantCode: '',
            tenantSsn: '',
            tenantId: '',
            tenantName: '',
            emergencyContactName: '',
            emergencyContactPhoneNumber: '',
            emergencyContactEmail: '',
            uploadAttachments: false,
        };
    }

    pingServer() {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        };
        const apiUrl = globalVar.homeApiUrl + '/pingserver/missinginformation';
        fetch(apiUrl, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                // console.log("user exists?: ", data);
                if (data !== true) {
                    window.location.href = '/error'; // redirect to error page
                }
            }).catch(function () {
                window.location.href = '/error';
            });
    }

    confirmCode() {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        };
        const apiUrl = globalVar.homeApiUrl + '/check-code/' + this.getUrlParam();
        fetch(apiUrl, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                // console.log("user exists?: ", data);
                if (data !== true) {
                    window.location.href = '/error'; // redirect to error page
                }
            }).catch(function () {
                window.location.href = '/error';
            });
    }

    componentDidMount() {
        this.pingServer();
        this.confirmCode();
    }

    myChangeHandler = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({ [nam]: val });
        // console.log('nam : ',nam);
        // console.log('val : ',val);
    }

    mySubmitHandler = (event) => {
        event.preventDefault();
        alert('Please wait while your information is submitted');
        // const formData = new FormData();
        if (newArr && newArr.length > 0) {
            this.state.uploadAttachments = true;
            formData.append(`tenantCode`, this.state.tenantCode);
            this.handleUpload(formData);
        }
        const apiUrl = globalVar.homeApiUrl + '/missinginformation';
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(this.state)
        };
        fetch(apiUrl, requestOptions)
            .then(data => {
                if (data.ok) {
                    alert('Your information has been successfully submitted.');
                    window.location.href = '/login';
                }
                else {
                    alert('Your information was not submitted, please try again or contact your property manager.');
                }
            }
            )
            .catch(err => alert(err));
    }

    validateForm = () => {
        if (this.state.tenantSsn && this.state.tenantName && this.state.emergencyContactName && this.state.emergencyContactPhoneNumber && this.state.emergencyContactEmail) {
            // console.log("this.state: ", this.state);
            // console.log('true ');
            return true;
        }
        else {
            // console.log('false ');
            return false;
        }
    }

    exitPage = () => {
        window.location.href = '/login'; // redirect to home page
    }

    handleFileInput = (e) => {
        newArr = e.target.files;
        if (newArr && newArr.length > 0) {
            for (let i = 0; i < newArr.length; i++) {
                if (newArr[i].size > 50e6) {
                    window.alert("Please upload a file smaller than 50 MB");
                }
                else {
                    formData.append(`incomingFiles`, newArr[i]);
                }
            }
        }
    }

    handleUpload = async (formData) => {
        const apiUrl = globalVar.homeApiUrl + '/upload-files-missing-info';
        const requestOptions = {
            method: 'POST',
            // headers: { 'Content-Type': 'application/json' },
            body: formData
        };
        fetch(apiUrl, requestOptions)
            .then(data => {
                data.ok ?
                    alert('Your documents have been successfully submitted.') :
                    alert('Your documents were not submitted, please try again or contact your property manager.');
            })
            .catch(err => alert(err));
    }

    getUrlParam = () => {
        let params = new URLSearchParams(window.location.search);
        let code = params.get("code");
        this.state.tenantCode = code;
        return code;
    }

    render() {
        return (
            <div>
                <br /><br />
                <label>Please upload your Driver's License Front and Back (you can upload multiple documents):</label>
                <br />
                <input type="file" multiple onChange={this.handleFileInput} />
                <br /><br />
                <label>Please upload your Proof of Income documents (last W2, last 2 paystubs, you can upload multiple documents):</label>
                <br />
                <input type="file" multiple onChange={this.handleFileInput} />
                <br /><br />
                <label>Miscellaneous documents (you can upload upto 10 documents):</label>
                <br />
                <input type="file" multiple onChange={this.handleFileInput} />
                <br />
                <input class="form-control w-50" type='text' name='tenantCode' value={this.getUrlParam()} hidden readOnly />
                <br />
                <p>Name: <i style={{ color: 'red' }}>(Required)</i></p>
                <input class="form-control w-50" type='text' name='tenantName' required onChange={this.myChangeHandler} />
                <br />
                <p>SSN (numbers only, no '-' or spaces): <i style={{ color: 'red' }}>(Required)</i></p>
                <input class="form-control w-50" type='number' placeholder="123456789" pattern="[0-9]{9}" name='tenantSsn' required onChange={this.myChangeHandler} />
                <br />
                <p>Emergency Contact Name: <i style={{ color: 'red' }}>(Required)</i></p>
                <input class="form-control w-50" type='text' name='emergencyContactName' required onChange={this.myChangeHandler} />
                <br />
                <p>Emergency Contact Email: <i style={{ color: 'red' }}>(Required)</i></p>
                <input class="form-control w-50" type='email' name='emergencyContactEmail' required onChange={this.myChangeHandler} />
                <br />
                <p>Emergency Contact Phone Number: <i style={{ color: 'red' }}>(Required)</i></p>
                <input class="form-control w-50" type='number' placeholder="1234567890" pattern="[0-9]{10}" name='emergencyContactPhoneNumber' required onChange={this.myChangeHandler} />
                <br /><br />
                <Button onClick={this.mySubmitHandler} disabled={!this.validateForm()}>
                    Submit
                </Button>
                <br /><br /><br /><br />
            </div>
        );
    }
}

export default MissingInformation;