import React from 'react';
import './main.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
    BrowserRouter as Router,
    Link
  } from "react-router-dom";

class LandingPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showServiceTicket: 'false'
        };
    }

  state= { renderServiceTicket: false, renderLandingPage: true }
  add = () => {
    this.setState({renderServiceTicket : !this.state.renderServiceTicket, renderLandingPage : !this.state.renderLandingPage})
  }

    render() {
        return (
            <div className="LandingPage">
                <h3 style={{ color: 'blue' }}>Welcome To Your Property Management Portal</h3>
                <br />
                <h4>Select an option</h4>
                <br />
                <Link to="/rentalapplication" className="btn btn-primary">Fill a Rental Application</Link>
                &emsp;&emsp;
                <Link to="/login" className="btn btn-primary">Login to your account</Link>
            </div>
        );
    }
}
export default LandingPage;