import React from 'react';
import './main.css';
import 'bootstrap/dist/css/bootstrap.min.css';

class NoPublicAccess extends React.Component {
    render() {
        return (
            <div>
                <title>5XX Level Errors page</title>
                <h3> 5XX Level Errors </h3>
                <h3>::CLOUDFLARE_ERROR_500S_BOX::</h3>
            </div>
        );
    }
}
export default NoPublicAccess;