import React from 'react'
import './main.css';

function Footer() {
  return (
          <div className="ServiceTicket-footer">
        
      <h6><em>Joe Bhaktiar <br />
        Licensed Real Estate Agent<br />
        VA #0225240403<br />
        National Realty LLC<br />
        11890 Sunrise Valley Drive<br />
        Reston, VA 20191<br />
        Phone: (202)286-5630</em></h6>
      
    </div>
  )
}

export default Footer;