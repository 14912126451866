import React from 'react';
import { Button } from "react-bootstrap";
import globalVar from './globalVar';

class Login extends React.Component {
  constructor() {
    super();
    this.state = {
      email: '',
      dob: '',
    };
  }

  pingServer() {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    };
    const apiUrl = globalVar.homeApiUrl + '/pingserver/login';
    fetch(apiUrl, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        // console.log("user exists?: ", data);
        if (data !== true) {
          window.location.href = '/error'; // redirect to error page
        }
      }).catch(function () {
        window.location.href = '/error';
      });
  }

  componentDidMount() {
    this.pingServer();
  }

  myChangeHandler = (event) => {
    let nam = event.target.name;
    let val = event.target.value;
    this.setState({ [nam]: val });
    // console.log('nam : ',nam);
    // console.log('val : ',val);
  }

  mySubmitHandler = (event) => {
    const apiUrl = globalVar.homeApiUrl + '/authenticatetenant';
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(this.state)
    };
    fetch(apiUrl, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        // console.log("user exists?: ", data);
        if (data !== null) {
          globalVar.authenticate = true
          globalVar.email = this.state.email
          globalVar.dob = this.state.dob
          globalVar.tenantId = data
          localStorage.setItem('globalVar', JSON.stringify(globalVar));
          window.location.href = '/personalportal';  // redirects
        }
        else {
          alert("User not found. Please try again or contact your property manager")
        }
      }).catch(function () {
        console.log("error");
        window.location.href = '/error';  // redirects
      });
  }

  validateForm = () => {
    if (this.state.email && this.state.dob) {
      // console.log("this.state: ", this.state);
      // console.log('true ');
      return true;
    }
    else {
      // console.log('false ');
      return false;
    }
  }

  exitPage = () => {
    window.location.href = '/login'; // redirect to home page
  }

  render() {
    return (
      <div style={{
        position: 'absolute', left: '50%', top: '50%',
        transform: 'translate(-50%, -40%)'
      }}>
        <br /><br />
        <h3 style={{ color: '#0d6efd' }}>Login to your account</h3>
        <br /><br />
        <p>Email: <i style={{ color: 'red' }}>(Required)</i></p>
        <input class="form-control w-75" type='email' name='email' required onChange={this.myChangeHandler} />
        <br />
        <p>Date of Birth: <i style={{ color: 'red' }}>(Required)</i></p>
        <input class="form-control w-75" type='date' name='dob' max={new Date().toISOString().split('T')[0]} required onChange={this.myChangeHandler} />
        <br /><br />
        <Button onClick={this.mySubmitHandler} disabled={!this.validateForm()}>
          Submit
        </Button>
        <br /><br /><br /><br />
      </div>
    );
  }
}

export default Login;