import React from 'react';
import './main.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Form, Button } from "react-bootstrap";
// import globalVar from './globalVar';
import { Navigate, useNavigate } from 'react-router-dom';

const globalVar = JSON.parse(localStorage.getItem('globalVar'));

let newArr = null;

class ServiceTicket extends React.Component {
    constructor() {
        super();
        this.state = {
            propertyAddress: '',
            propertyId: '',
            shortDescription: '',
            longDescription: '',
            tenant_Id: '',
            issueStartDate: '',
            issueCategory: '',
        };
    }

    pingServer() {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        };
        const apiUrl = globalVar.homeApiUrl + '/pingserver/serviceticket';
        fetch(apiUrl, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                // console.log("user exists?: ", data);
                if (data !== true) {
                    window.location.href = '/error'; // redirect to error page
                }
            }).catch(function () {
                window.location.href = '/error';
            });
    }

    componentDidMount() {
        this.pingServer();
        const apiUrl = globalVar.homeApiUrl + '/getproperty';
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(globalVar)
        };
        fetch(apiUrl, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                this.setState({
                    propertyAddress: data.address,
                    propertyId: data.id
                })
            })
            .catch(error => {
                console.log('error : ');
                // console.log('This is your error', error);
                useNavigate().push('/error');
            });
    }

    // this is what happens when the form is submitted
    mySubmitHandler = (event) => {
        event.preventDefault();
        alert('Please wait while your information is submitted');
        const formData = new FormData();
        if (newArr && newArr.length > 0) {
            for (let i = 0; i < newArr.length; i++) {
                // this.handleUpload(newArr[i]);
                formData.append(`incomingFiles`, newArr[i]);
            }
            formData.append(`tenantId`, globalVar.tenantId);
            this.handleUpload(formData);
        }
        // alert("You are submitting " + this.state.shortDescription+this.state.propertyAddress+
        // this.state.longDescription);
        const apiUrl = globalVar.homeApiUrl + '/createnewticket?shortDescription=' + this.state.shortDescription +
            '&longDescription=' + this.state.longDescription + '&propertyId=' + this.state.propertyId +
            '&tenantId=' + globalVar.tenantId + '&issueCategory=' + this.state.issueCategory +
            '&issueStartDate=' + this.state.issueStartDate;
        const requestOptions = {
            method: 'POST'
        };
        fetch(apiUrl, requestOptions)
            .then(data => {
                if (data.ok) {
                    alert('Your ticket has been successfully submitted.');
                    window.location.href = '/personalportal';
                }
                else {
                    alert('Your ticket was not submitted, please try again or contact your property manager.');
                }
            }
            )
            .catch(err => alert(err));
    }
    // this is what happens when text is entered in text boxes
    myChangeHandler = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({ [nam]: val });
        // console.log('nam : ',nam);
        // console.log('val : ',val);
    }

    validateForm() {
        if (this.state.propertyAddress && this.state.shortDescription && this.state.longDescription && this.state.issueStartDate
            && this.state.issueCategory) {
            // console.log("this.state: ", this.state);
            // console.log('true ');
            return true;
        }
        else {
            // console.log('false ');
            return false;
        }
    }

    goToPersonalPortal() {
        window.location.href = '/personalportal';
    }

    filesUpload() {
        return (
            <div>
                <br /><br />
                <div>Select images or videos to upload: <i style={{ color: 'red' }}>(Recommended)</i></div>
                <br />
                <input type="file" multiple onChange={this.handleFileInput} />
                <br />
            </div>
        );
    }

    handleFileInput = (e) => {
        newArr = e.target.files;
    }

    handleUpload = async (formData) => {
        const apiUrl = globalVar.homeApiUrl + '/upload-files-service-ticket';
        const requestOptions = {
            method: 'POST',
            // headers: { 'Content-Type': 'application/json' },
            body: formData
        };
        fetch(apiUrl, requestOptions)
            .then(data => {
                data.ok ?
                    alert('Your files have been successfully submitted.') :
                    alert('Your files were not submitted, please try again or contact your property manager.');
            })
            .catch(err => alert(err));
    }

    render() {
        if (globalVar.authenticate) {
            return (
                <Form onSubmit={this.mySubmitHandler}>
                    <div className="ServiceTicket">
                        <br />
                        <h3 style={{ color: 'blue' }}>Enter specific details of your issue below:</h3>
                        <br />
                        <p>Property Address: <i style={{ color: 'red' }}>(Required)</i></p>
                        <select>{
                            <option> {this.state.propertyAddress}</option>
                        }</select>
                        <br /><br />
                        <Form onChange={this.myChangeHandler}>
                            Category: <i style={{ color: 'red' }}>(Required)</i><br /><br />
                            <select name="issueCategory" id="issueCategory" style={{ width: '310px' }}>
                                <option value="" selected="selected">Select</option>
                                <option value="appliance" >Appliance</option>
                                <option value="hvac">Heating and Cooling</option>
                                <option value="electrical">Electrical</option>
                                <option value="plumbing">Plumbing</option>
                                <option value="other">Other</option>
                            </select>
                        </Form>
                        <br />
                        <Form.Group size="lg">
                            <Form.Label>When did the issue start: <i style={{ color: 'red' }}>(Required)</i></Form.Label>
                            <Form.Control
                                required
                                autoFocus
                                name='issueStartDate'
                                type='date'
                                max={new Date().toISOString().split('T')[0]}
                                onChange={this.myChangeHandler}
                            />
                        </Form.Group>
                        <br>
                        </br>
                        <Form.Group size="lg">
                            <Form.Label>Enter short description of your issue: <i style={{ color: 'red' }}>(Required)</i></Form.Label>
                            <Form.Control
                                required
                                autoFocus
                                name='shortDescription'
                                onChange={this.myChangeHandler}
                            />
                        </Form.Group>
                        <br />
                        {/* <p>Enter short description of your issue:</p>
                        <input type='text' name='name' onChange={this.myChangeHandler}/>
                        <br /><br /> */}
                        <p>Enter your issue in detail. For faster response, please enter as many
                            details as possible: <i style={{ color: 'red' }}>(Required)</i></p>
                        <textarea rows={10} cols={50} name='longDescription' required onChange={this.myChangeHandler} />
                        <br />
                        <div>
                            {this.filesUpload()}
                        </div>
                        <br /><br />
                        <Button variant="primary" type="submit" disabled={!this.validateForm()}>
                            Submit
                        </Button>&emsp;&emsp;
                        <Button className="btn btn-primary" onClick={this.goToPersonalPortal}>Cancel</Button>
                    </div>
                </Form>
            ); //end of return
        }
        else {
            return (
                <Navigate to="/login" />
            );
        }
    }
}
export default ServiceTicket;