import React from 'react';
import { Button } from "react-bootstrap";

const globalVar = JSON.parse(localStorage.getItem('globalVar'));
const formData = new FormData();
class TenantInfoRelease extends React.Component {
    constructor() {
        super();
        this.state = {
            tenantId: null,
            isSpecificPerson: false,
            specificName: null, //set to null because using for validation
            isAnyRequestor: false,
            startDate: null, //set to null because using for validation
            endDate: null, //set to null because using for validation
            isTermsConditionsAgree: false,
        };
    }

    pingServer() {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        };
        const apiUrl = globalVar.homeApiUrl + '/pingserver/tenant-info-release';
        fetch(apiUrl, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                if (data !== true) {
                    window.location.href = '/login'; // redirect to login page
                }
            }).catch(function () {
                window.location.href = '/login';
            });
    }

    componentDidMount() {
        try {
            let auth = globalVar.authenticate;
            this.pingServer();
            this.setState({ tenantId: globalVar.tenantId });
        }
        catch (err) {
            window.location.href = '/login';
        }
    }

    myChangeHandlerRadioButton = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({ [nam]: val });
        // console.log('nam : ',nam);
        // console.log('val : ',val);

        if (nam === 'isSpecificPerson' && val === "true") {
            this.setState({ isAnyRequestor: false });
            document.getElementById("isAnyRequestor").checked = false;
        }
        if (nam === 'isAnyRequestor' && val === "true") {
            this.setState({ isSpecificPerson: false });
            document.getElementById("isSpecificPerson").checked = false;
        }
        this.setState({ startDate: null });
        this.setState({ endDate: null });
        this.setState({ specificName: null });
    }

    myChangeHandler = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({ [nam]: val });
    }

    mySubmitHandler = (event) => {
        event.preventDefault();
        const apiUrl = globalVar.homeApiUrl + '/tenantinforelease';
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(this.state)
        };
        fetch(apiUrl, requestOptions)
            .then(data => {
                if (data.ok) {
                    alert('Your information has been successfully submitted.');
                    window.location.href = '/personalportal';
                }
                else {
                    alert('Your information was not submitted, please try again or contact your property manager.');
                }
            }
            )
            .catch(err => alert(err));
    }

    validateForm = () => {
        if (this.state.isTermsConditionsAgree && (this.state.isSpecificPerson && this.state.specificName != null || this.state.isAnyRequestor)
            && this.state.startDate != null && this.state.endDate != null) {
            return true;
        }
        else {
            return false;
        }
    }

    exitPage = () => {
        window.location.href = '/login'; // redirect to home page
    }

    flipCheckBox = (event) => {
        let nam = event.target.name;
        let val = this.state[nam];
        val = !val;
        this.setState({ [nam]: val });
    }

    render() {
        try {
            let auth = globalVar.authenticate;
            return (
                <div className="TenantInfoRelease">
                    <h4 style={{ color: 'blue', textAlign: 'center' }}>TENANT INFORMATION RELEASE FORM</h4>
                    <br />
                    <div class="border border-2 rounded pt-3 px-3">
                        <p>
                            I authorize the release of information concerning my stay at properties
                            managed by Joe Bhaktiar. This information can include but is
                            not limited to duration of stay, rent amounts, payment history, property
                            condition during my stay and upon my departure, and issues concerning compliance with or
                            infringement on the policies and provisions in the Lease Agreement. This
                            information may be provided to property managers and landlords with whom I am seeking tenancy,
                            bank representatives evaluating my credit worthiness, and or any agencies
                            conducting background checks.
                        </p>
                        <p class="form-check" className="CenterAlign">
                            <input class="form-check-input" type="checkbox" name="isTermsConditionsAgree" id="isTermsConditionsAgree" onChange={this.flipCheckBox} />&nbsp;
                            <label class="form-check-label" for="flexCheckDefault"> I Understand </label>
                        </p>
                    </div>
                    <br /><br />
                    <div class="pt-3 px-3 pl-3 pr-3 pb-3">
                    <p><b><h6>Please select one of the following: <i style={{ color: 'red' }}>(Required)</i></h6></b></p>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="isSpecificPerson" id="isSpecificPerson" value="true" onChange={this.myChangeHandlerRadioButton} />
                        <label class="form-check-label" for="inlineRadio1">Provide my information only to the specific person/party mentioned below</label>
                    </div>
                    {this.state.isSpecificPerson === "true" && ((
                        <div class="pb-3">
                            <p>Name of person/party to whom information is to be disclosed: <i style={{ color: 'red' }}>(Required)</i></p>
                            <input class="form-control w-50" type='text' name='specificName' onChange={this.myChangeHandler} />
                            <br />
                            <div className="row">
                                <div className="col">
                                    <p>Start date: <i style={{ color: 'red' }}>(Required)</i></p>
                                    <input class="form-control w-50" type='date' name='startDate' min={new Date().toISOString().split('T')[0]}
                                        onChange={this.myChangeHandler} />
                                </div>
                                <div className="col">
                                    <p>End date: <i style={{ color: 'red' }}>(Required)</i></p>
                                    <input class="form-control w-50" type='date' name='endDate' min={this.state.startDate}
                                        onChange={this.myChangeHandler} />
                                </div>
                            </div>
                        </div>
                    )
                    )}
                    <br />

                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="isAnyRequestor" id="isAnyRequestor" value="true" onChange={this.myChangeHandlerRadioButton} />
                        <label class="form-check-label" for="inlineRadio1">Provide my information to any requestor</label>
                    </div>
                    {this.state.isAnyRequestor === "true" && ((
                        <div class="pb-3">
                            <p>During the following time period: </p>
                            <div className="row">
                                <div className="col">
                                    <p>Start date: <i style={{ color: 'red' }}>(Required)</i></p>
                                    <input class="form-control w-50" type='date' name='startDate' min={new Date().toISOString().split('T')[0]}
                                        onChange={this.myChangeHandler} />
                                </div>
                                <div className="col">
                                    <p>End date: <i style={{ color: 'red' }}>(Required)</i></p>
                                    <input class="form-control w-50" type='date' name='endDate' min={this.state.startDate}
                                        onChange={this.myChangeHandler} />
                                </div>
                            </div>
                        </div>
                    )
                    )}
                    <br /><br />
                    <Button onClick={this.mySubmitHandler} disabled={!this.validateForm()}>
                        Submit
                    </Button>
                    </div>
                    <br /><br /><br /><br />
                </div>
            );
        }
        catch (err) {
            console.log(err);
        }
    }
}

export default TenantInfoRelease;