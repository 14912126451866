import React from 'react';
import './main.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Form, Button } from "react-bootstrap";
import globalVar from './globalVar';
import OtherOccupants from './OtherOccupants';

// const globalVar = JSON.parse(localStorage.getItem('globalVar'));

class RentalApplication extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            propertyAddress: '',
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            dropDownOptions: [],
            dateOfBirth: '',
            currentAddress: '',
            lengthAtCurrentAddress: '',
            occupancyType: '',
            landlordName: '',
            landlordPhone: '',
            landlordEmail: '',
            currentIncome: '',
            creditScore: '',
            isBankruptcy: '',
            bankruptcyExplanation: '',
            isEvicted: '',
            evictedExplanation: '',
            isJudgements: '',
            judgementsExplanation: '',
            isForeclosures: '',
            foreclosuresExplanation: '',
            isLawsuits: '',
            lawsuitsExplanation: '',
            isTermsConditionsAgree: false,
            isDiplomat: '',
            numberOfOccupants: '',
            isPets: '',
            numOfPets: 0,
            petType: '',
            petBreed: '',
            petColor: '',
            petName: '',
            petAge: '',
            petWeight: '',
            petGender: '',
            isPetDeclawed: '',
            isPetNeutured: '',
            petsObjList: [],
            petsList: ['input-0'],
            savePetButton: '',
            projectedMoveinDate: '',
            projectedLeaseLength: '',
            // isOtherOccupants:'',
            // otherOccupantsList: ['input-0'],
        };
    }

    pingServer() {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        };
        const apiUrl = globalVar.homeApiUrl + '/pingserver/rentalapplication';
        fetch(apiUrl, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                // console.log("user exists?: ", data);
                if (data !== true) {
                    window.location.href = '/error'; // redirect to error page
                }
            }).catch(function () {
                window.location.href = '/error';
            });
    }

    componentDidMount() {
        this.pingServer();
        const apiUrl = globalVar.homeApiUrl + '/getallactivepropertiesfordropdown';
        fetch(apiUrl)
            .then((response) => response.json())
            .then((data) => {
                // console.log('data : ', data);
                this.setState({ dropDownOptions: data })
            }
            )
            .catch(error => {
                console.log('error : ');
                // console.log('This is your error', error);
            });
    }

    // this is what happens when the form is submitted
    mySubmitHandler = (event) => {
        event.preventDefault();
        alert('Please wait while your application is being submitted');
        const apiUrl = globalVar.homeApiUrl + '/createnewrentalapplication';
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(this.state)
        };
        fetch(apiUrl, requestOptions)
            .then(data => {
                if (data.ok) {
                    alert('Your application has been successfully submitted, someone will contact you shortly.');
                    window.location.href = '/login';
                }
                else {
                    alert('Your application was not submitted, please try again or contact your property manager.');
                }
            }
            )
            .catch(err => alert(err));
    }
    // this is what happens when text is entered in text boxes
    myChangeHandler = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({ [nam]: val });
        // console.log("nam: ", nam);
        // console.log("val: ", val);
    }
    // this is what happens when a checkbox is clicked
    flipCheckBox = (event) => {
        let nam = event.target.name;
        let val = this.state[nam];
        val = !val;
        this.setState({ [nam]: val });
    }

    validateForm() {
        if (this.state.isTermsConditionsAgree) {
            return true;
        }
        else {
            return false;
        }
    }

    validateIUnderstand() {
        if (this.state.propertyAddress && this.state.occupancyType && this.state.isBankruptcy
            && this.state.isEvicted && this.state.isJudgements && this.state.isForeclosures && this.state.isLawsuits
            && this.state.isDiplomat && this.state.isPets) {
            if (this.state.isPets === "true") {
                if (this.state.savePetButton === "true") {
                    return true;
                }
                else {
                    document.getElementById("isTermsConditionsAgree").checked = false;
                    this.state.isTermsConditionsAgree = false;
                    return false;
                }
            }
            else {
                return true;
            }
            // console.log("this.state: ", this.state);
            // console.log('true ');
            // return true;
        }
        else {
            // console.log('false ');
            this.state.isTermsConditionsAgree = false;
            return false;
        }
    }

    validatePetForm() {
        if (this.state.petType && this.state.petBreed && this.state.petAge && this.state.petWeight
            && this.state.petGender && this.state.isPetDeclawed && this.state.isPetNeutured
            && this.state.petColor && this.state.petName) {
            // console.log("this.state: ", this.state);
            // console.log('true ');
            return true;
        }
        else {
            // console.log('false ');
            return false;
        }
    }

    validateAddAnotherPetButton() {
        if (this.state.savePetButton === "true") {
            return true;
        }
        else {
            return false;
        }
    }

    enterPetDetails() {
        return (
            <div>
                <br /><br />
                <p>Pet type (Cat/Dog etc.): <i style={{ color: 'red' }}>(Required)</i></p>
                <input class="form-control w-50" type='text' name='petType' required onChange={this.myChangeHandler} />
                <br />
                <p>Pet breed: <i style={{ color: 'red' }}>(Required)</i></p>
                <input class="form-control w-50" type='text' name='petBreed' required onChange={this.myChangeHandler} />
                <br />
                <p>Pet weight (in lbs): <i style={{ color: 'red' }}>(Required)</i></p>
                <input class="form-control w-50" type='number' min="1" max="500" step="1" name='petWeight' required onChange={this.myChangeHandler} />
                <br />
                <p>Pet Color: <i style={{ color: 'red' }}>(Required)</i></p>
                <input class="form-control w-50" type='text' name='petColor' required onChange={this.myChangeHandler} />
                <br />
                <p>Pet Name: <i style={{ color: 'red' }}>(Required)</i></p>
                <input class="form-control w-50" type='text' name='petName' required onChange={this.myChangeHandler} />
                <br />
                <p>Pet gender: <i style={{ color: 'red' }}>(Required)</i></p>
                <input class="form-check-input" type="radio" name="petGender" id="petGender" value="Male" onChange={this.myChangeHandler} />&nbsp;&nbsp;
                <label class="form-check-label" for="petGenderMale">Male</label>&nbsp;&nbsp;&nbsp;&nbsp;
                <input class="form-check-input" type="radio" name="petGender" id="petGender" value="Female" onChange={this.myChangeHandler} />&nbsp;&nbsp;
                <label class="form-check-label" for="petGenderFemale">Female</label>
                <br /><br />
                <p>Is the pet neutured/spayed?: <i style={{ color: 'red' }}>(Required)</i></p>
                <input class="form-check-input" type="radio" name="isPetNeutured" id="isPetNeutured" value="true" onChange={this.myChangeHandler} />&nbsp;&nbsp;
                <label class="form-check-label" for="isPetNeuturedYes">Yes</label>&nbsp;&nbsp;&nbsp;&nbsp;
                <input class="form-check-input" type="radio" name="isPetNeutured" id="isPetNeutured" value="false" onChange={this.myChangeHandler} />&nbsp;&nbsp;
                <label class="form-check-label" for="isPetNeuturedNo">No</label>
                <br /><br />
                <p>Is the pet declawed?: <i style={{ color: 'red' }}>(Required)</i></p>
                <input class="form-check-input" type="radio" name="isPetDeclawed" id="isPetDeclawed" value="true" onChange={this.myChangeHandler} />&nbsp;&nbsp;
                <label class="form-check-label" for="isPetDeclawedYes">Yes</label>&nbsp;&nbsp;&nbsp;&nbsp;
                <input class="form-check-input" type="radio" name="isPetDeclawed" id="isPetDeclawed" value="false" onChange={this.myChangeHandler} />&nbsp;&nbsp;
                <label class="form-check-label" for="isPetDeclawedNo">No</label>
                <br /><br />
                <p>Pet Age (years): <i style={{ color: 'red' }}>(Required)</i></p>
                <input class="form-control w-50" type='number' min="1" max="150" step="1" name='petAge' required onChange={this.myChangeHandler} />
                <br /><br />
                <Button ref="btn" id="savePetButton" name="savePetButton" value="true" onClick={this.savePet} disabled={!this.validatePetForm()}>
                    Save pet
                </Button>
            </div>
        );
    }

    savePet = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({ [nam]: val });
        // console.log("nam: ", nam);
        // console.log("val: ", val);

        let petObj = {
            petType: '',
            petBreed: '',
            petAge: '',
            petWeight: '',
            petColor: '',
            petName: '',
            petGender: '',
            isPetDeclawed: '',
            isPetNeutured: '',
        };

        petObj.petAge = this.state.petAge;
        petObj.petBreed = this.state.petBreed;
        petObj.petGender = this.state.petGender;
        petObj.petType = this.state.petType;
        petObj.petWeight = this.state.petWeight;
        petObj.petColor = this.state.petColor;
        petObj.petName = this.state.petName;
        petObj.isPetDeclawed = this.state.isPetDeclawed;
        petObj.isPetNeutured = this.state.isPetNeutured;
        this.state.petsObjList.push(petObj);

        // console.log("this.state.petsObjList ", this.state.petsObjList);

        this.refs.btn.setAttribute("disabled", "disabled");
    }

    addPet() {
        var newInput = `input-${this.state.petsList.length}`;
        this.setState(prevState => ({ petsList: prevState.petsList.concat([newInput]) }));
    }

    // addOtherOccupants() {
    //     var newInput = `input-${this.state.otherOccupantsList.length}`;
    //     this.setState(prevState => ({ otherOccupantsList: prevState.otherOccupantsList.concat([newInput]) }));
    // }

    render() {
        return (
            <div className="RentalApplication">
                <Form onSubmit={this.mySubmitHandler}>
                    <br />
                    <h4 style={{ color: 'blue' }}>Welcome to the pre-screen rental application (Free of cost).
                        Applicants who fill this application will be given preference over those without.</h4>
                    <br />
                    <div class="border border-2 rounded px-3 pt-3 pb-3">
                        <p>Select Property: <i style={{ color: 'red' }}>(Required)</i></p>
                        <select class="form-control w-50" name='propertyAddress' required onChange={this.myChangeHandler}>
                            <option value="none" selected disabled hidden>
                                Select an Option
                            </option>
                            {
                                this.state.dropDownOptions.map((obj) => {
                                    return <option value={obj.address}>{obj.address}</option>
                                })
                            }</select>
                        <br />
                        <p>Enter your first name: <i style={{ color: 'red' }}>(Required)</i></p>
                        <input class="form-control w-50" type='text' name='firstName' required onChange={this.myChangeHandler} />
                        <br />
                        <p>Enter your last name: <i style={{ color: 'red' }}>(Required)</i></p>
                        <input class="form-control w-50" type='text' name='lastName' required onChange={this.myChangeHandler} />
                        <br />
                        <p>Enter your email: <i style={{ color: 'red' }}>(Required)</i></p>
                        <input class="form-control w-50" type='email' name='email' required onChange={this.myChangeHandler} />
                        <br />
                        <p>Enter your phone number: <i style={{ color: 'red' }}>(Required)</i></p>
                        <input class="form-control w-50" type='tel' name='phoneNumber' placeholder="1234567890" pattern="[0-9]{10}" required onChange={this.myChangeHandler} />
                        <br />
                        <p>Enter your date of birth: <i style={{ color: 'red' }}>(Required)</i></p>
                        <input class="form-control w-50" type='date' name='dateOfBirth' max={new Date().toISOString().split('T')[0]} required onChange={this.myChangeHandler} />
                        <br />
                        <p>Enter your current address: <i style={{ color: 'red' }}>(Required)</i></p>
                        <input class="form-control w-50" type='text' name='currentAddress' required onChange={this.myChangeHandler} />
                        <br />
                        <p>How long (in months) have you lived at your current address:</p>
                        <input class="form-control w-50" type="number" min="1" max="1000" step="1" name='lengthAtCurrentAddress' onChange={this.myChangeHandler} />
                        <br />
                        <p>Do you currently rent or own?: <i style={{ color: 'red' }}>(Required)</i></p>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="occupancyType" id="inlineRadio1" value="Rent" onChange={this.myChangeHandler} />
                            <label class="form-check-label" for="inlineRadio1">Rent</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="occupancyType" id="inlineRadio2" value="Own" onChange={this.myChangeHandler} />
                            <label class="form-check-label" for="inlineRadio2">Own</label>
                        </div>
                        <br /><br />
                        <p>Landlord/Management/Mortgage company name: <i style={{ color: 'red' }}>(Required)</i></p>
                        <input class="form-control w-50" type='text' name='landlordName' required onChange={this.myChangeHandler} />
                        <br />
                        <p>Landlord/Management/Mortgage company phone number: <i style={{ color: 'red' }}>(Required)</i></p>
                        <input class="form-control w-50" type='tel' name='landlordPhone' placeholder="1234567890" pattern="[0-9]{10}" required onChange={this.myChangeHandler} />
                        <br />
                        <p>Landlord/Management/Mortgage company email: <i style={{ color: 'red' }}>(Required)</i></p>
                        <input class="form-control w-50" type='email' name='landlordEmail' required onChange={this.myChangeHandler} />
                        <br />
                        <p>What is your total yearly income? (USD): <i style={{ color: 'red' }}>(Required)</i></p>
                        <input class="form-control w-50" type="number" min="1" step="1" name='currentIncome' required onChange={this.myChangeHandler} />
                        <br />
                        <p>What is your approximate credit score?: <i style={{ color: 'red' }}>(Required)</i></p>
                        <input class="form-control w-50" type="number" min="1" max="850" step="1" name='creditScore' required onChange={this.myChangeHandler} />
                        <br />
                        <p>How many people will be occupying the property? <i style={{ color: 'red' }}>(Required)</i></p>
                        <input class="form-control w-50" type="number" min="1" max="10" step="1" name='numberOfOccupants' required onChange={this.myChangeHandler} />
                        <br />
                        <p>What is your projected move-in date? <i style={{ color: 'red' }}>(Required)</i></p>
                        <input class="form-control w-50" type="date" name='projectedMoveinDate' min={new Date().toISOString().split('T')[0]} required onChange={this.myChangeHandler} />
                        <br />
                        <p>How long of a lease (in years) are you looking for? (2 years and longer leases will get a preference) <i style={{ color: 'red' }}>(Required)</i></p>
                        <input class="form-control w-50" type="number" min="1" max="5" step="1" name='projectedLeaseLength' required onChange={this.myChangeHandler} />
                    </div>
                    <br /><br />
                    <div class="border border-2 rounded px-3 pt-3 pb-3" >
                        <h5>Please answer the following questions to the best of your knowledge</h5>

                        <div className="row">
                            <div className="col">
                                <label class="form-check-label" for="flexCheckDefault"> Have you ever filed for bankruptcy? <i style={{ color: 'red' }}>(Required)</i></label>
                            </div>
                            <div className="col">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="isBankruptcy" id="isBankruptcy" value="true" onChange={this.myChangeHandler} />
                                    <label class="form-check-label" for="inlineRadio1">Yes</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="isBankruptcy" id="isBankruptcy" value="false" onChange={this.myChangeHandler} />
                                    <label class="form-check-label" for="inlineRadio2">No</label>
                                </div>
                            </div>
                            {this.state.isBankruptcy === "true" && ((
                                <div class="pb-3">
                                    <p>Explanation: <i style={{ color: 'red' }}>(Required)</i></p>
                                    <input class="form-control w-50" type='text' name='bankruptcyExplanation' required onChange={this.myChangeHandler} />
                                </div>
                            )
                            )}
                            <br />
                        </div>

                        <div className="row">
                            <div className="col">
                                <label class="form-check-label" for="flexCheckDefault"> Have you ever been evicted? <i style={{ color: 'red' }}>(Required)</i></label>
                            </div>
                            <div className="col">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="isEvicted" id="isEvicted" value="true" onChange={this.myChangeHandler} />
                                    <label class="form-check-label" for="inlineRadio1">Yes</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="isEvicted" id="isEvicted" value="false" onChange={this.myChangeHandler} />
                                    <label class="form-check-label" for="inlineRadio2">No</label>
                                </div>
                            </div>
                            {this.state.isEvicted === "true" && ((
                                <div class="pb-3">
                                    <p>Explanation: <i style={{ color: 'red' }}>(Required)</i></p>
                                    <input class="form-control w-50" type='text' name='evictedExplanation' required onChange={this.myChangeHandler} />
                                </div>
                            )
                            )}
                            <br />
                        </div>

                        <div className="row">
                            <div className="col">
                                <label class="form-check-label" for="flexCheckDefault"> Do you have any judgments? <i style={{ color: 'red' }}>(Required)</i></label>
                            </div>
                            <div className="col">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="isJudgements" id="isJudgements" value="true" onChange={this.myChangeHandler} />
                                    <label class="form-check-label" for="inlineRadio1">Yes</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="isJudgements" id="isJudgements" value="false" onChange={this.myChangeHandler} />
                                    <label class="form-check-label" for="inlineRadio2">No</label>
                                </div>
                            </div>
                            {this.state.isJudgements === "true" && ((
                                <div class="pb-3">
                                    <p>Explanation: <i style={{ color: 'red' }}>(Required)</i></p>
                                    <input class="form-control w-50" type='text' name='judgementsExplanation' required onChange={this.myChangeHandler} />
                                </div>
                            )
                            )}
                            <br />
                        </div>

                        <div className="row">
                            <div className="col">
                                <label class="form-check-label" for="flexCheckDefault"> Have you had a foreclosure? <i style={{ color: 'red' }}>(Required)</i></label>
                            </div>
                            <div className="col">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="isForeclosures" id="isForeclosures" value="true" onChange={this.myChangeHandler} />
                                    <label class="form-check-label" for="inlineRadio1">Yes</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="isForeclosures" id="isForeclosures" value="false" onChange={this.myChangeHandler} />
                                    <label class="form-check-label" for="inlineRadio2">No</label>
                                </div>
                            </div>
                            {this.state.isForeclosures === "true" && ((
                                <div class="pb-3">
                                    <p>Explanation: <i style={{ color: 'red' }}>(Required)</i></p>
                                    <input class="form-control w-50" type='text' name='foreclosuresExplanation' required onChange={this.myChangeHandler} />
                                </div>
                            )
                            )}
                            <br />
                        </div>

                        <div className="row">
                            <div className="col">
                                <label class="form-check-label" for="flexCheckDefault"> Are you party to a lawsuit? <i style={{ color: 'red' }}>(Required)</i></label>
                            </div>
                            <div className="col">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="isLawsuits" id="isLawsuits" value="true" onChange={this.myChangeHandler} />
                                    <label class="form-check-label" for="inlineRadio1">Yes</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="isLawsuits" id="isLawsuits" value="false" onChange={this.myChangeHandler} />
                                    <label class="form-check-label" for="inlineRadio2">No</label>
                                </div>
                            </div>
                            {this.state.isLawsuits === "true" && ((
                                <div class="pb-3">
                                    <p>Explanation: <i style={{ color: 'red' }}>(Required)</i></p>
                                    <input class="form-control w-50" type='text' name='lawsuitsExplanation' required onChange={this.myChangeHandler} />
                                </div>
                            )
                            )}
                            <br />
                        </div>

                        <div className="row">
                            <div className="col">
                                <label class="form-check-label" for="flexCheckDefault"> Are you entitled to diplomatic immunity? <i style={{ color: 'red' }}>(Required)</i></label>
                            </div>
                            <div className="col">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="isDiplomat" id="isDiplomat" value="true" onChange={this.myChangeHandler} />
                                    <label class="form-check-label" for="inlineRadio1">Yes</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="isDiplomat" id="isDiplomat" value="false" onChange={this.myChangeHandler} />
                                    <label class="form-check-label" for="inlineRadio2">No</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br /><br />
                    <div class="border border-2 rounded px-3 pt-3 pb-3" >
                        <h5>Animals</h5>
                        <div className="row">
                            <div className="col">
                                <label class="form-check-label" for="flexCheckDefault"> Do you have any pets that will be living with you?<i style={{ color: 'red' }}>(Required)</i></label>&emsp;
                            </div>
                            <div className="col">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="isPets" id="isPets" value="true" onChange={this.myChangeHandler} />
                                    <label class="form-check-label" for="inlineRadio1">Yes</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="isPets" id="isPets" value="false" onChange={this.myChangeHandler} />
                                    <label class="form-check-label" for="inlineRadio2">No</label>
                                </div>
                            </div>
                            {this.state.isPets === "true" && (
                                <div>
                                    <p>How many pets do you have? <i style={{ color: 'red' }}>(Required)</i></p>
                                    <input class="form-control w-50" type='number' name='numOfPets' min="1" max="5" step="1" required onChange={this.myChangeHandler} />
                                    {
                                        // this.state.petsList.map(input => <Pets/>)
                                        this.state.petsList.map(input => this.enterPetDetails())
                                    }
                                    <br /><br />
                                    <Button onClick={() => this.addPet()} disabled={!this.validateAddAnotherPetButton()}>
                                        Add another pet
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>
                    {/* Other Occupants Component Disabled */}
                    {/* <br /><br />
                <div class="border border-2 rounded px-3 pt-3 pb-3" >
                    <h5>Other Occupants</h5>
                    <label class="form-check-label" for="flexCheckDefault"> Do you have other occupants that will be living with you?<i style={{ color: 'red' }}>(Required)</i></label>&emsp;
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="isOtherOccupants" id="isOtherOccupants" value="true" onChange={this.myChangeHandler}/>
                        <label class="form-check-label" for="inlineRadio1">Yes</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="isOtherOccupants" id="isOtherOccupants" value="false" onChange={this.myChangeHandler}/>
                        <label class="form-check-label" for="inlineRadio2">No</label>
                    </div>
                    { this.state.isOtherOccupants === "true" && (
                        <Form>
                            {this.state.otherOccupantsList.map(input => <OtherOccupants/>)}
                            <br />
                         <Button onClick={ () => this.addOtherOccupants() }>
                            Add an occupant
                        </Button>
                        </Form>
                    ) }
                </div> */}
                    <br /><br />
                    <div class="border border-2 rounded pt-3 px-3 pl-3 pr-3 pb-3">
                        <p>
                            <h5>Offer to rent:</h5>
                            <p>I offer to lease the property at {this.state.propertyAddress} for {this.state.projectedLeaseLength} years beginning {this.state.projectedMoveinDate},
                                for the monthly rent as mentioned in the listing, payable in advance on the first dy of each month. Applicant(s)
                                acknowledge that monthy rent may be subject to increase in subsequent years.</p>
                            <h5>Conditions:</h5>
                            <p>Application is not complete until an <b>Application Deposit</b> is included along with the application. The deposit will be held by the Landlord. If this
                                application is accepted and a lease is signed, the Deposit will be credited to amounts owed to Landlord. If this Application is denied, or the
                                parties cannot agree on terms to a lease, any Deposit will be returned to Applicant(s).
                            </p>
                            <h5>Applicant(s) acknowledge:</h5>
                            <ol type="1">
                                <li>This Application, each occupant, and each pet are subject to acceptance and approval by Landlord.</li>
                                <li>Listing Broker is obligated to present all Applications to Landlord until a lease is signed.</li>
                                <li>Landlord and Listing Broker may rescind acceptance and resume marketing the Premises at any time until a lease is signed.</li>
                                <li>Proof of current income is required. For example:</li>
                                <ol type="a">
                                    <li>Latest Pay Statements/Stubs</li>
                                    <li>Last 2 years’ Form W-2 for hourly or weekly pay persons</li>
                                    <li>Last 2 years’ Form 1040 and Schedule C (if applicable) of self-employed or persons with tip income</li>
                                    <li>Copy of LES and orders for military</li>
                                </ol>
                                <li>All required fields in this Application must be completed in full. Incomplete or missing information will result in delay of a decision. Willful misrepresentation may be grounds for invalidating a lease.</li>
                                <li>A draft of the proposed lease may be reviewed through Listing Broker. If Landlord and Applicant(s) cannot agree on terms, the Deposit will be returned.</li>
                                <li>Applicant(s) must present valid photo identification or two (2) forms of ID and SSN before signing the lease.</li>
                                <li>Applicant(s) is responsible for obtaining property and liability insurance (Renter’ s Insurance) and assuming utility accounts where required before occupying the Premises.</li>
                                <li>Any move-in fees and utility deposits are the responsibility of Applicant(s).</li>
                                <li>Only those persons listed in Application are to live in the Premises.</li>
                                <li>The Premises are not to be used for business except with full knowledge and consent of Landlord and in conformity with all applicable laws and regulations.</li>
                                <li>Applicant(s) has no leasehold interest until a lease is signed.</li>
                                <li>Occupancy is subject to possession being delivered by the present occupant. <b>The Premises are accepted “As-Is” unless
                                    otherwise noted below or by attachment.</b>
                                </li>
                                <li>I have recieved a copy of the <a href="https://www.dhcd.virginia.gov/sites/default/files/Docx/landlord-tenant/statement-of-tenant-rights-and-responsibilities-vmlrha2022.pdf" target="_blank">
                                    <em>Virginia Statement of Tenant Rights and Responsibilities</em></a>.
                                </li>
                                <li>By filling this application, the Applicant permits the Landlord to request references from any applicable parties whose contact is provided in this application.</li>
                            </ol>
                        </p>
                        <p class="form-check" className="CenterAlign">
                            <input class="form-check-input" type="checkbox" name="isTermsConditionsAgree" id="isTermsConditionsAgree" onChange={this.flipCheckBox} disabled={!this.validateIUnderstand()} />&nbsp;
                            <label class="form-check-label" for="flexCheckDefault"> I Understand </label>
                        </p>
                    </div>
                    <p></p>
                    <Button variant="primary" type="submit" disabled={!this.validateForm()}>
                        Submit
                    </Button>
                </Form>
                <p></p>
                <div class="pt-3 px-3 pl-3 pr-3 pb-3"><h6>Helpful Resources:</h6>
                    <a href="https://www.nvar.com/docs/default-source/pdfs/standardized-forms/jan-1-2023/k1354ed---lease-vrlta.pdf?sfvrsn=16446c0c_2" target="_blank"><em>VRLTA LEASE</em></a>
                    <br />
                    <a href="https://www.dhcd.virginia.gov/sites/default/files/Docx/landlord-tenant/statement-of-tenant-rights-and-responsibilities-vmlrha2022.pdf" target="_blank">
                        <em>Virginia Statement of Tenant Rights and Responsibilities</em></a>
                    <br />
                </div>
            </div>
        );
    }
}
export default RentalApplication;