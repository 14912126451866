import React from 'react';
import { Button } from "react-bootstrap";
import globalVar from './globalVar';

// const globalVar = JSON.parse(localStorage.getItem('globalVar'));
let newArr = '';
const formData = new FormData();
class MissingDocuments extends React.Component {
    constructor() {
        super();
        this.state = {
            tenantName: '',
            uploadAttachments: false,
        };
    }

    pingServer() {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        };
        const apiUrl = globalVar.homeApiUrl + '/pingserver/missingdocuments';
        fetch(apiUrl, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                // console.log("user exists?: ", data);
                if (data !== true) {
                    window.location.href = '/error'; // redirect to error page
                }
            }).catch(function () {
                window.location.href = '/error';
            });
    }

    componentDidMount() {
        this.pingServer();
    }

    myChangeHandler = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({ [nam]: val });
        // console.log('nam : ',nam);
        // console.log('val : ',val);
    }

    mySubmitHandler = (event) => {
        event.preventDefault();
        alert('Please wait while your information is submitted');
        if (newArr && newArr.length > 0) {
            this.state.uploadAttachments = true;
            formData.append(`uploadAttachments`, this.state.uploadAttachments);
        }
        formData.append(`tenantName`, this.state.tenantName);
        const apiUrl = globalVar.homeApiUrl + '/upload-missing-documents';
        const requestOptions = {
            method: 'POST',
            // headers: { 'Content-Type': 'application/json' },
            body: formData
        };
        fetch(apiUrl, requestOptions)
            .then(data => {
                data.status !== 200 ?
                    alert('Form was submitted but NO files were uploaded!') :
                    alert('Your files have been successfully submitted.');
                window.location.href = '/login';
            }
            )
            .catch(err => alert(err));
    }

    validateForm = () => {
        if (this.state.tenantName) {
            // console.log("this.state: ", this.state);
            // console.log('true ');
            return true;
        }
        else {
            // console.log('false ');
            return false;
        }
    }

    handleFileInput = (e) => {
        newArr = e.target.files;
        if (newArr && newArr.length > 0) {
            for (let i = 0; i < newArr.length; i++) {
                if (newArr[i].size > 50e6) {
                    window.alert("Please upload a file smaller than 50 MB");
                }
                else {
                    formData.append(`incomingFiles`, newArr[i]);
                }
            }
        }
    }

    render() {
        return (
            <div>
                <br /><br />
                <label>Please upload your Driver's License Front and Back (you can upload multiple documents):</label>
                <br />
                <input type="file" multiple onChange={this.handleFileInput} />
                <br /><br />
                <label>Please upload your Proof of Income documents (last W2, last 2 paystubs, you can upload multiple documents):</label>
                <br />
                <input type="file" multiple onChange={this.handleFileInput} />
                <br /><br />
                <label>Miscellaneous documents (you can upload upto 10 documents):</label>
                <br />
                <input type="file" multiple onChange={this.handleFileInput} />
                <br /><br />
                <p>Name: <i style={{ color: 'red' }}>(Required)</i></p>
                <input class="form-control w-50" type='text' name='tenantName' required onChange={this.myChangeHandler} />
                <br />
                <Button onClick={this.mySubmitHandler} disabled={!this.validateForm()}>
                    Submit
                </Button>
                <br /><br /><br /><br />
            </div>
        );
    }
}

export default MissingDocuments;