import React from 'react';
import './main.css';
import 'bootstrap/dist/css/bootstrap.min.css';

class OtherOccupants extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          firstName:'',
          lastName:'',
          gender:'',
          age:'',
          relationship:'',
        };
    }

    // this is what happens when text is entered in text boxes
    myChangeHandler = (event) => {
      let nam = event.target.name;
      let val = event.target.value;
      this.setState({[nam]: val});
  //     console.log("nam: ", nam);
  //     console.log("val: ", val);
  }

    render() {
        return (
          <div>
          <br /><br />
            <p>First name: <i style={{ color: 'red' }}>(Required)</i></p> 
            <input class="form-control w-50" type='text' name='firstName' required onChange={this.myChangeHandler}/>
            <br />
            <p>Last name: <i style={{ color: 'red' }}>(Required)</i></p> 
            <input class="form-control w-50" type='text' name='lastName' required onChange={this.myChangeHandler}/>
            <br />
            <p>Age: <i style={{ color: 'red' }}>(Required)</i></p> 
            <input class="form-control w-50" type='number' min="1" max="150" step="1" name='age' required onChange={this.myChangeHandler}/>
            <br />
            <p>Gender: <i style={{ color: 'red' }}>(Required)</i></p> 
            <input class="form-control w-50" type='text' name='gender' required onChange={this.myChangeHandler}/>
            <br />
            <p>Relationship: <i style={{ color: 'red' }}>(Required)</i></p> 
            <input class="form-control w-50" type='text' name='relationship' required onChange={this.myChangeHandler}/>
            <br />
          </div>
        );
    }
}
export default OtherOccupants;