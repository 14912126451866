export default {
    userExist: false,
    authenticate: false,
    email:'',
    dob:'',
    tenantId:'',
    // homeApiUrl:'http://localhost:8080',
    homeApiUrl:'https://api.propertymanagementexp.com',
    virginiaTenantRightsUrl: 'https://www.dhcd.virginia.gov/sites/default/files/Docx/landlord-tenant/statement-of-tenant-rights-and-responsibilities-vmlrha2022.pdf',
    vrltaLeaseUrl: 'https://www.nvar.com/docs/default-source/pdfs/standardized-forms/jan-1-2023/k1354ed---lease-vrlta.pdf?sfvrsn=16446c0c_2',
    headers: {
        "Content-type": "application/json"
    }
};