import React from 'react';
import './main.css';
import { Button } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  Link, Navigate
} from "react-router-dom";
// import globalVar from './globalVar';
const globalVar = JSON.parse(localStorage.getItem('globalVar'));

class PersonalPortalHome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      propertyId: '',
      community: '',
      tenantName: '',
      trashDays: '',
      leaseStartDate: '',
      leaseEndDate: '',
      animalRulesAndRegulationsDocumentLink: '',
      rulesAndRegulationsDocumentLink: ''
    };
  }

  pingServer() {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    };
    const apiUrl = globalVar.homeApiUrl + '/pingserver/personalportal';
    fetch(apiUrl, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        // console.log("user exists?: ", data);
        if (data !== true) {
          window.location.href = '/error'; // redirect to error page
        }
      }).catch(function () {
        window.location.href = '/error';
      });
  }

  userLogout = () => {
    localStorage.clear();
    window.location.href = '/login';
  }

  componentDidMount() {
    try {
      // let auth = globalVar.authenticate;
      this.pingServer();
      const apiUrl = globalVar.homeApiUrl + '/getpropertyforpersonalportal';
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(globalVar)
      };
      fetch(apiUrl, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            propertyId: data.id,
            community: data.community,
            tenantName: data.tenantName,
            trashDays: data.trashDays,
            leaseStartDate: data.leaseStartDate,
            leaseEndDate: data.leaseEndDate,
            animalRulesAndRegulationsDocumentLink: data.animalRulesAndRegulationsDocumentLink,
            rulesAndRegulationsDocumentLink: data.rulesAndRegulationsDocumentLink
          })
        })
        .catch(error => {
          console.log('This is your error', error);
          window.location.href = '/login';
        });
    } catch (err) {
      window.location.href = '/login';
    }
  }

  render() {
    try {
      let auth = globalVar.authenticate;
      return (
        <div className="container PersonalPortalHome">
          <div className="row">
            <div className="col-sm-3 PersonalPortalHomeVerticalLine">
              <b>Welcome </b><em>{this.state.tenantName}!</em>
              <br /><br />
              Please review: <a href="https://www.dhcd.virginia.gov/sites/default/files/Docx/landlord-tenant/statement-of-tenant-rights-and-responsibilities-vmlrha2022.pdf" target="_blank">
                <em>Virginia Statement of Tenant Rights and Responsibilities</em></a>
              <br /><br />
              <b>Your Commmunity:</b>
              <br />
              <em>{this.state.community}</em>
              <br /><br />
              <b>Trash Days:</b>
              <br />
              <em>{this.state.trashDays}</em>
              <br /><br />
              <b>Your Lease Start Date:</b>
              <br />
              <em>{this.state.leaseStartDate}</em>
              <br /><br />
              <b>Your Lease End Date:</b>
              <br />
              <em>{this.state.leaseEndDate}</em>
              <br /><br />
              <b>Your Association Documents:</b>
              <br />
              <a href={this.state.animalRulesAndRegulationsDocumentLink} target="_blank"><em>Pets Rules and Regulations</em></a>
              <br />
              <a href={this.state.rulesAndRegulationsDocumentLink} target="_blank"><em>Association Rules and Regulations</em></a>
              <br />
            </div>
            <div className="col-sm-3 PersonalPortalHomeVerticalLine">
              <b>Contractors List</b> - To get a discount, please mention that I referred you. I don't make any profit
              from this recommendation but I have used these people in the past and know that they do a good job for a fair price. You are encouraged to DYOR.
              <br /><br />
              <b>Carpet Steam Clean: </b>
              <br />
              <em>Dan - 703-867-2000</em>
              <br />
              <b>Drywall repair and paint: </b>
              <br />
              <em>Martinez - 540-542-9751</em>
              {(() => {
                if (this.state.propertyId === 6) {
                  return (
                    <div>
                      <b>Landscaping: </b>
                      <br />
                      <em>Benjamin Garcia - 202-422-0695</em>
                    </div>
                  )
                } else {
                  return (
                    <div>
                      <b>Landscaping: </b>
                      <br />
                      <em>Simon - 703-929-4474</em>
                    </div>
                  )
                }
              })()}
              <b>Gutter Cleaning: </b>
              <br />
              <em><a href="https://guttermaid.com/" target="_blank"><em>https://guttermaid.com</em></a></em>
            </div>
            <div className="col-sm-3 PersonalPortalHomeVerticalLine">
              <b>Utility Company List</b> - Below are the utility companies that you will need to call to transfer utilities over to your name 2 weeks before
              your lease start date. Please tell them you want them <b>'transferred' to your name on the first day of your lease to avoid unnecessary charges</b>.
              It's also a good idea to create an online account for all utility companies so you can set up for autopay with each one.
              <br /><br />
              <a href="https://www.dominionenergy.com/" target="_blank"><em>Dominion Electric</em></a>
              <br />
              {(() => {
                if (this.state.propertyId === (3 || 5 || 7 || 9)) {
                  return (
                    <a href="https://www.columbiagasva.com/" target="_blank"><em>Columbia Gas</em></a>
                  )
                } else {
                  return (
                    <a href="https://www.washingtongas.com/" target="_blank"><em>Washington Gas</em></a>
                  )
                }
              })()}
              {(() => {
                if (this.state.propertyId === 6) {
                  return (
                    <div>
                      <a href="https://www.fairfaxwater.org/" target="_blank"><em>Fairfax Water</em></a> - I will initiate the process with Fairfax water and you will hear
                      from either me or directly from them to set up your account
                    </div>
                  )
                } else {
                  return (
                    <div>
                      <a href="https://www.loudounwater.org/" target="_blank"><em>Loudoun Water</em></a> - I will initiate the process with Loudoun water and you will hear
                      from either me or directly from them to set up your account
                    </div>
                  )
                }
              })()}

            </div>
            <div className="col-sm-3 CenterAlign PersonalPortalHomeVerticalLine">
              <Link to="/tenantinforelease" className="btn btn-primary">Tenant Information Release Form</Link><br /><br />
              <Link to="/serviceticket" className="btn btn-primary">Create a Service Ticket</Link><br /><br />
              <Button className="btn btn-primary" onClick={this.userLogout}>Logout</Button>
            </div>
          </div>
        </div>
      );
    }
    catch (err) {
      console.log(err);
    }
  }
}
export default PersonalPortalHome;