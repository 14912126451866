import React from 'react';
import './main.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Routes, Route } from "react-router-dom";
import LandingPage from './LandingPage';
import ServiceTicket from './ServiceTicket';
import Login from './Login';
import RentalApplication from './RentalApplication';
import Header from './Header';
import Footer from './Footer';
import PersonalPortalHome from './PersonalPortalHome';
import ErrorPage from './ErrorPage';
import MissingInformation from './MissingInformation';
import MissingDocuments from './MissingDocuments';
import ErrorBoundary from './ErrorBoundary';
import NoPublicAccess from './NoPublicAccess';
import TenantInfoRelease from './TenantInfoRelease'
import MissingSsn from './MissingSsn';

export default function App() {
  return (
    <div>
      <Routes>

        {/* <Route path="/missinginfo" element={<><ErrorBoundary /><Header /><MissingSsn /><Footer /></>} /> */}

        <Route path="/missinginformation/*" element={<><ErrorBoundary /><Header /><MissingInformation /><Footer /></>} />{/* Example: /missinginformation/?code=<tenant code> */}

        <Route path="/missingdocuments" element={<><ErrorBoundary /><Header /><MissingDocuments /><Footer /></>} />

        <Route path="/error" element={<><ErrorBoundary /><ErrorPage /></>} />

        <Route path="/personalportal" element={<><ErrorBoundary /><Header /><PersonalPortalHome /><Footer /></>} />

        <Route path="/rentalapplication" element={<><ErrorBoundary /><Header /><RentalApplication /></>} />

        <Route path="/serviceticket" element={<><ErrorBoundary /><Header /><ServiceTicket /><Footer /></>} />

        <Route path="/login" element={<><ErrorBoundary /><Header /><Login /></>} />

        <Route path="/tenantinforelease" element={<><ErrorBoundary /><Header /><TenantInfoRelease /><Footer /></>} />

        <Route path="/agentid-joebhaktiar" element={<><ErrorBoundary /><Header /><LandingPage /></>} />

        <Route path="/" element={<><ErrorBoundary /><NoPublicAccess /></>} />

      </Routes>
    </div>
  );
}