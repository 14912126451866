import React from 'react';
import './main.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';

class ErrorPage extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
        hasError: false,
      };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
  }

  render() {
    return (
        <div className="CenterAlign">
          <br /><br />
            <h5>Something appears wrong, please try again later.</h5>
            <br />
            {/* <Link to="/" className="btn btn-primary">Return to home page</Link>
            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /> */}
        </div>
    );
  }
}
export default ErrorPage;